.marquee-container {
  overflow: hidden;
}

.marquee {
  white-space: nowrap;
  width: auto;
  animation: marquee 20s linear infinite;
}

.marquee span {
  display: inline-block;
  width: 25%;
}

@keyframes marquee {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
